<template>
  <div class="add">
    <el-form ref="form" style="width: 80%" :inline="false" :model="form" label-width="120px">
      <el-form-item label="店铺" prop="shopIds" :rules="selectRules">
        <el-cascader :options="shopOptions"
                     v-model="form.shopIds"
                     :props="{
                        value: 'id',
                        label: 'name',
                        children: 'childList'
                     }"
                     ref="cascader"
                     size="small"
                     filterable
                     clearable>
        </el-cascader>
      </el-form-item>
      <el-form-item label="罚金理由" prop="reason" :rules="inputRules">
        <el-input v-model="form.reason" size="small" placeholder="请输入" maxlength="50" show-word-limit></el-input>
      </el-form-item>
      <el-form-item label="罚金类型" prop="penalTypes" :rules="selectRules">
        <el-cascader
          :options="ticketTypes"
          :props="{
            value: 'id',
            label: 'typeName',
            children: 'options',
            multiple: true
          }"
          v-model="form.penalTypes"
          @change="changeTicketFun"
          size="small"
          filterable
          collapse-tags
          clearable>
        </el-cascader>
      </el-form-item>
      <el-form-item label="详情">
        <TicketDetail :options="penalTypes" :total="penalTotal + '元'"/>
      </el-form-item>
      <el-form-item label="最晚处理时间" prop="estimateTime" :rules="inputRules">
        <el-date-picker
          v-model="form.estimateTime"
          :picker-options="{
            disabledDate: (time) => {
              return time.getTime() < Date.now() - 24*3600*1000
            }
          }"
          size="small"
          value-format="yyyy-MM-dd"
          type="date"
          placeholder="选择日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="备注">
        <el-input type="textarea" size="small" :rows="4" maxlength="100" show-word-limit v-model="form.remark" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="上传图片/视频" prop="resource" :rules="uploadRules">
        <d-upload :file-list.sync="form.resource" :limit="10" :multiple-preview="true"></d-upload>
      </el-form-item>
    </el-form>
    <div class="footer">
      <el-button type="primary" size="large" @click="submitFun">提交</el-button>
      <el-button type="info" size="large" @click="cancelFun">取消</el-button>
    </div>
  </div>
</template>

<script>
import DUpload from "@/components/d-upload";
import TicketDetail from '../components/ticket-detail.vue'
export default {
  name: 'Add',
  components: { DUpload, TicketDetail },
  data () {
    return {
      form: {
        shopIds: [],
        reason: '',
        penalTypes: [],
        estimateTime: '',
        remark: '',
        resource: [],
      },
      penalTypes: [],
      penalTotal: 0,
      shopOptions: [],
      ticketTypes: [],
      selectRules:[{ required: true, message: '请选择'} ],
      inputRules:[{ required: true, message: '请输入'} ],
      uploadRules: [{ required: true, message: '请上传'}]
    }
  },
  watch: {
    'form.resource': {
      handler (val) {
        const func = val ? 'clearValidate' : 'validateField'
        this.$refs.form && this.$refs.form[func]('resource')
      },
      deep: true
    }
  },
  created () {
    this.getShopList()
    this.getItemList()
  },
  methods: {
    submitFun () {
      this.$refs.form.validate(valid => {
        if(valid) {
          const { penalTypes } = this.setParams()
          const { estimateTime, reason, remark, resource, shopIds } = this.form
          const data = {
            estimateTime,
            resource,
            reason,
            remark,
            penalTypes,
            shopId: shopIds[shopIds.length - 1]
          }
          this.$api.merchant.addTicketApi(data).then(({ code, msg }) => {
            if(code === 200) {
              this.$message.success(msg)
              return this.$router.push({ path: '/businessModule/merchant/ticket' })
            }
            return this.$message.warning(msg)
          })
        }
      })
    },
    changeTicketFun (data) {
      const { penalTypes, total } = this.setParams(data)
      this.penalTypes = penalTypes
      this.penalTotal = total
    },
    setParams () {
      const list = this.form.penalTypes
      let total = 0
      let obj = null
      list.forEach(e => {
        const [parent, child] = e;
        const [parentId, parentName] = parent.split("-");
        const parentStr = `${parentId}-${parentName}`
        const [childId, childName, childNum] = child.split("-");
        if (childNum) {
          total += Number(childNum);
        }
        (obj || (obj = {})) && (obj[parentStr] || (obj[parentStr] = [])).push({penalOptionId: childId, penalOptionName: childName, penalSum: childNum + '元' })
      })
      const arr = []
      for(let key in obj) {
        const [penalTypeId, penalTypeName] = key.split('-')
        arr.push({
          penalTypeId,
          penalTypeName,
          penalOptions: obj[key]
        })
      }
      return {
        penalTypes: arr,
        total: total.toFixed(2),
      };
    },
    cancelFun () {
      this.$router.back()
    },
    getShopList () {
      this.$api.shopapi.getShopList()
        .then(({ data, code }) => {
          if (code == 200) {
            this.childList = data.childList
            const child = [{
              id: data.id,
              name: data.name,
              childList: data.childList
            }]
            this.shopOptions = child
          }
        })
    },
    getItemList () {
      const data = {
        pageNum: -1,
        pageSize: 10,
      }
      this.$api.merchant.getMerchantTicketLisyApi(data).then(({ code, data}) => {
        if(code !== 200) return
        this.ticketTypes = (data.list || []).map(e => {
          e.id = `${e.id}-${e.typeName}`
          e.options && e.options.forEach(item => {
            item.id = `${item.id}-${item.optionName}-${Number(item.penalSum).toFixed(2)}`
            item.typeName = item.optionName
          });
          return e
        })
      })
    },

  }
}
</script>

<style lang="scss" scoped>
.add {
  padding: 30px;
  background-color: #fff;
  overflow-y: auto;
  .footer {
    margin: 20px;
    text-align: right;
  }
}
</style>