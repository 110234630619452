<template>
  <div class="ticket-detail">
    <template v-if="options.length">
      <div class="ticket_wrap">
        <div v-for="option in options" :key="option.penalTypeId">
          <div class="classify_name">{{ option.penalTypeName }}</div>
          <div class="item">
            <div v-for="item in option.penalOptions" :key="item.penalOptionId">
              {{ item.penalOptionName }} 
              <span class="childNum">{{ item.penalSum }}</span>
            </div>
          </div>
        </div>
      </div>
    </template>

    <div v-if="total" class="total">
      共计：
      <span>{{ total }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "TicketDetail",
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    total: {
      type: String,
      default: ''
    }
  }
};
</script>

<style scoped lang="scss">
.ticket-detail {
  background-color: #f9f9f9;
  border-radius: 8px;
  min-height: 200px;
  .ticket_wrap {
    padding: 20px;
    .item > div {
      padding: 2px 0;
      line-height: 20px;
    }
  }
  .classify_name {
    font-size: 18px;
    line-height: 30px;
    font-weight: bold;
  }
  .childNum {
    font-weight: bold;
  }
  .total {
    padding: 20px;
    border-top: 1px solid #d7d7d7;
    text-align: right;
    span {
      font-size: 20px;
      font-weight: bold;
    }
  }
}
</style>